.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-footer;
  border-top: 1px solid #e9e9f1;

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: $screen-lg;
    margin: 0 auto;
    padding: 38px 40px 36px;
    box-sizing: content-box;
  }

  &__copyright {
    font-size: 14px;
    color: #b3b3b3;
    text-transform: uppercase;
  }

  &__list {
    font-size: 0;
  }

  &__item {
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      .footer__link {
        padding-right: 0;
      }
    }
  }

  &__link {
    padding: 0 12px;
    font-size: 14px;
    color: #b3b3b3;

    &--bold {
      font-weight: 700;
    }
  }
}

@include breakpoint(tablet) {
  .footer {
    background-color: #f7f7f7;

    &__inner {
      padding: 24px 40px;
    }

    &__copyright {
      font-size: 12px;
    }

    &__item {
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 10px;
        background-color: #d8d8d8;
        vertical-align: middle;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }
    }

    &__link {
      padding: 0 10px;
      font-size: 12px;
      vertical-align: middle;
    }
  }
}

@include breakpoint(mobile-small) {
  .footer {
    &__inner {
      flex-direction: column;
    }

    &__copyright {
      max-width: 150px;
      line-height: 16px;
    }

    &__list {
      margin-top: 28px;
    }

    &__item {
      &:first-child {
        .footer__link {
          padding-left: 0;
        }
      }
    }
  }
}
