@use "sass:math";

$mobile-vars: (
	'careers': (
		offset-x: 0px,
		offset-y: -392px,
		width: 134px,
		height: 38px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'careers_on': (
		offset-x: -300px,
		offset-y: -344px,
		width: 134px,
		height: 44px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'company': (
		offset-x: -356px,
		offset-y: -392px,
		width: 150px,
		height: 22px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'company_on': (
		offset-x: 0px,
		offset-y: -344px,
		width: 152px,
		height: 44px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'contact': (
		offset-x: -438px,
		offset-y: -344px,
		width: 140px,
		height: 38px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'contact_on': (
		offset-x: -156px,
		offset-y: -344px,
		width: 140px,
		height: 44px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'creator_title': (
		offset-x: 0px,
		offset-y: -300px,
		width: 568px,
		height: 40px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'impact': (
		offset-x: -138px,
		offset-y: -392px,
		width: 112px,
		height: 38px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'impact_on': (
		offset-x: 0px,
		offset-y: -434px,
		width: 112px,
		height: 44px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'media': (
		offset-x: -254px,
		offset-y: -392px,
		width: 98px,
		height: 38px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'media_on': (
		offset-x: -116px,
		offset-y: -434px,
		width: 98px,
		height: 44px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'service_wattpad': (
		offset-x: 0px,
		offset-y: 0px,
		width: 297px,
		height: 296px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
	'service_webnovel': (
		offset-x: -301px,
		offset-y: 0px,
		width: 296px,
		height: 296px,
		total-width: 597px,
		total-height: 478px,
		imageSrc: '../img/mobile.png'
	),
);
$mobile-origin: (
  total-width: 597px,
  total-height: 478px,
  imageSrc: '../img/mobile.png'
);

@mixin sprite-mobile-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-mobile-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-mobile-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-mobile {
  @include sprite-mobile-size($mobile-origin);
  @include sprite-mobile-image($mobile-origin);
}

@mixin sp-mobile($image, $size: true) {
  @include set-mobile;
	background-position: math.div(map-get(map-get($mobile-vars, $image), 'offset-x'), 2)
	math.div(map-get(map-get($mobile-vars, $image), 'offset-y'), 2);
	@if $size {
		width: math.div(map-get(map-get($mobile-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($mobile-vars, $image), 'height'), 2);
	}
}
