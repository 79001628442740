.header {
  position: relative;
  background-color: #fff;

  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-index-header;
    background-color: transparent;
  }

  &.is-active {
    border-bottom: 1px solid #d8d8d8;

    .wrap-main & {
      background-color: #fff;
    }
  }

  h1 {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }

  &__inner {
    display: flex;
    justify-content: center;
    max-width: 2280px;
    height: 120px;
    margin: 0 auto;
    padding: 0 40px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
      width: 116px;
      height: 39px;
      background-position: 0 -2565px;
    }
  }

  .gnb {
    margin-left: 10px;

    &__list {
      display: flex;
      gap: 80px;
      height: 100%;
      align-items: center;
      font-size: 0;
    }

    &__item {
      display: inline-block;

      &.company {
        .gnb__link::before {
          @include sp-desktop('company');
        }

        &.on {
          .gnb__link::before {
            @include sp-desktop('company_on');
          }
        }
      }

      &.careers {
        .gnb__link::before {
          @include sp-desktop('careers');
        }

        &.on {
          .gnb__link::before {
            @include sp-desktop('careers_on');
          }
        }
      }

      &.contact {
        .gnb__link::before {
          @include sp-desktop('contact');
        }

        &.on {
          .gnb__link::before {
            @include sp-desktop('contact_on');
          }
        }
      }

      &.news {
        .gnb__link::before {
          @include sp-desktop('media');
        }

        &.on {
          .gnb__link::before {
            @include sp-desktop('media_on');
          }
        }
      }

      &.impact {
        .gnb__link::before {
          @include sp-desktop('impact');
        }

        &.on {
          .gnb__link::before {
            @include sp-desktop('impact_on');
          }
        }
      }
    }

    &__link {
      &::before {
        width: 140px;
        height: 50px;
      }
    }
  }

  .dropdown {
    position: relative;
    width: 126px;
    margin-top: 36px;
    z-index: $z-index-header;

    &__btn {
      position: relative;
      width: 100%;
      height: 45px;
      padding: 0 23px;
      border: 1px solid #00d564;
      border-radius: 23px;
      color: #00d564;
      font-size: 14px;
      text-align: left;

      &::before {
        content: '';
        position: absolute;
        top: 15px;
        right: 14px;
        width: 11px;
        height: 11px;
        background-position: -489px -15px;
      }

      &[aria-expanded='true'] {
        display: none;

        +.dropdown__list {
          display: block;
        }
      }
    }

    &__list {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      padding: 7px 0;
      border: 1px solid #00d564;
      border-radius: 23px;
      z-index: 11;
    }

    &__item {
      position: relative;

      &.on {
        .dropdown__option {
          color: #00d564;
        }
      }

      &.sp_ico {
        &::before {
          content: '';
          position: absolute;
          top: 10px;
          right: 14px;
          width: 11px;
          height: 11px;
          background-position: -489px -15px;
        }
      }
    }

    &__option {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 23px;
      font-size: 14px;
      color: #595e65;
      text-transform: uppercase;

      &::after {
        content: '';
        height: 100%;
        vertical-align: middle;
      }
    }
  }
}

@media screen and (max-width: 1130px) {
  .header {
    .gnb {
      margin-left: 0;

      &__list {
        gap: 12px;
      }
    }
  }
}

@include breakpoint(tablet) {
  .header {
    &.is-active {
      .gnb {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }

    &__inner {
      height: 60px;
      padding: 0 20px;
    }

    &__logo,
    .dropdown {
      display: none;

      .wrap-main & {
        display: block;
      }
    }

    &__link {
      &::before {
        width: 66px;
        height: 22px;
        background-position: 0 -2031px;
      }
    }

    .gnb {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: $z-index-header + 1;
      height: 60px;
      border-bottom: 1px solid #d8d8d8;
      background-color: #fff;

      .wrap-main & {
        position: fixed;
        visibility: hidden;
        opacity: 0;
        border-bottom: 0;
      }

      &.is-scroll {
        visibility: visible;
        opacity: 1;
      }

      &__list {
        justify-content: center;
        gap: 20px;
      }

      &__item {
        &.company {
          .gnb__link::before {
            @include sp-mobile('company');
          }

          &.on {
            .gnb__link::before {
              @include sp-mobile('company_on');
            }
          }
        }

        &.careers {
          .gnb__link::before {
            @include sp-mobile('careers');
          }

          &.on {
            .gnb__link::before {
              @include sp-mobile('careers_on');
            }
          }
        }

        &.contact {
          .gnb__link::before {
            @include sp-mobile('contact');
          }

          &.on {
            .gnb__link::before {
              @include sp-mobile('contact_on');
            }
          }
        }

        &.news {
          .gnb__link::before {
            @include sp-mobile('media');
          }

          &.on {
            .gnb__link::before {
              @include sp-mobile('media_on');
            }
          }
        }

        &.impact {
          .gnb__link::before {
            @include sp-mobile('impact');
          }

          &.on {
            .gnb__link::before {
              @include sp-mobile('impact_on');
            }
          }
        }
      }

      &__link {
        &::before {
          width: auto;
          height: 30px;
        }
      }
    }

    .dropdown {
      width: 90px;
      margin-top: 18px;

      &__btn {
        height: 32px;
        padding: 0 12px;
        border-radius: 16px;
        font-size: 10px;

        &::before {
          top: 11px;
          background-position: 0 -100px;
        }
      }

      &__list {
        padding: 4px 0;
        border-radius: 16px;
      }

      &__item {
        &.sp_ico {
          &::before {
            top: 11px;
            background-position: 0 -100px;
          }
        }
      }

      &__option {
        height: 22px;
        padding: 0 12px;
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .header {
    .gnb {
      &__list {
        overflow: hidden;
        overflow-x: auto;
        justify-content: flex-start;
        padding: 0 20px;
        gap: 18px;
      }
    }
  }
}
