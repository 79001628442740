.ethics {
  &__contents {
    display: flex;

    &+.ethics__contents {
      margin-top: 140px;
    }
  }

  &__title {
    flex-shrink: 0;
    min-width: 290px;
    font-weight: 600;
    font-size: 30px;
    color: #353b42;
  }

  &__item {
    position: relative;
    padding: 32px 0;
    border-top: 1px solid #e0e0e0;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 72px;
      height: 72px;
      background-image: url('../img/pc.png');
      background-size: 500px 3250px;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }

    &.employees {
      &::before {
        margin-top: -16px;
        background-position: 0 -2860px;
      }
    }

    &.partner {
      &::before {
        background-position: -82px -2860px;
      }
    }

    &.social {
      &::before {
        background-position: -164px -2860px;
      }
    }

    &.consumer {
      &::before {
        background-position: -246px -2860px;
      }
    }

    &.shareholder {
      &::before {
        background-position: -328px -2860px;
      }
    }
  }

  &__dl {
    padding-left: 112px;
  }

  &__dt {
    font-weight: 800;
    font-size: 17px;
    line-height: 30px;
    color: #353b42;
  }

  &__dd {
    margin-top: 4px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #595e65;
  }

  &__desc {
    margin-top: 30px;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #595e65;

    &:first-child {
      margin-top: 0;
    }
  }

  &__tbl {
    width: 100%;
    margin-top: 30px;
    border-bottom: 1px solid #595e65;

    tr {
      border-bottom: 1px solid #595e65;

      &:first-child {
        border-top: 2px solid #595e65;
      }

      &:last-child {
        border-bottom: 2px solid #595e65;
      }
    }

    th {
      width: 160px;
      padding: 28px 30px;
      font-weight: 600;
      font-size: 16px;
      color: #595e65;
      text-align: left;
      vertical-align: top;
    }

    td {
      padding: 28px 30px 24px 24px;
      border-left: 1px solid #595e65;
      font-weight: 600;
      font-size: 15px;
      color: #595e65;
    }

    dl+dl {
      margin-top: 12px;
    }

    dt, dd {
      display: inline-block;
    }
  }

  &__btn {
    height: 50px;
    margin-top: 12px;
    padding: 0 24px;
    border-radius: 25px;
    background-color: #353b42;
    font-size: 17px;
    color: $color-white;

    &::before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }

  &__email {
    color: #595e65;
  }
}

@include breakpoint(tablet) {
  .ethics {
    .cont_wrap {
      padding: 28px 20px 52px;
    }

    &__contents {
      flex-direction: column;

      &+.ethics__contents {
        margin-top: 38px;
      }
    }

    &__title {
      font-size: 22px;
    }

    &__list {
      margin-top: 20px;
    }

    &__item {
      padding: 22px 0;

      &::before {
        display: none;
      }
    }

    &__dl {
      padding-left: 0;
    }

    &__dt {
      font-size: 14px;
      line-height: 25px;
    }

    &__dd {
      margin-top: 8px;
      font-size: 14px;
      line-height: 24px;
    }

    &__box {
      margin-top: 38px;
    }

    &__desc {
      margin-top: 24px;
      font-size: 14px;
      line-height: 24px;
      color: #595e65;
    }

    &__tbl {
      margin-top: 48px;

      th {
        width: 80px;
        padding: 12px 10px;
        font-size: 12px;
      }

      td {
        padding: 12px 15px 12px 12px;
        font-size: 12px;
        line-height: 15px;
      }

      dl+dl {
        margin-top: 0;
      }
    }

    &__btn {
      height: 34px;
      margin-top: 8px;
      padding: 0 16px;
      border-radius: 25px;
      font-size: 12px;
    }
  }
}
