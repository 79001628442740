$screen-xsm: 639px;
$screen-sm: 767px;
$screen-md: 1023px;
$screen-lg: 1280px;
$screen-xlg: 1920px;

$color-black: #000;
$color-white: #fff;

$z-index-minus: -1;
$z-index-zero: 0;
$z-index-minimum: 1;
$z-index-header: 10;
$z-index-footer: 10;
$z-index-layer: 100;

$activeClass: 'is-active';
$showClass: 'is-show';
