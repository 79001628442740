.social {
  .cont_wrap {
    max-width: 1120px;
    padding-top: 80px;
  }

  &__list {
    margin: 34px -12px -12px;
    font-size: 0;
  }

  &__item {
    display: inline-block;
    width: 33.3333%;
    padding: 12px;

    &.youtube {
      .social__link {
        background-color: #ff0000;

        &::before {
          background-position: -433px -559px;
        }
      }
    }

    &.nstagram {
      .social__link {
        background: linear-gradient(to left, #bb02ad, #ed0217);

        &::before {
          background-position: -433px -643px;
        }
      }
    }

    &.twitter-x {
      .social__link {
        background-color: #000;
      }

      img {
        width: 72px;
      }
    }

    &.tiktok {
      .social__link {
        background-color: $color-black;

        &::before {
          background-position: -429px -820px;
        }
      }
    }

    &.facebook {
      .social__link {
        background-color: #1a77f2;

        &::before {
          background-position: -419px -917px;
        }
      }
    }

    &.navertv {
      .social__link {
        background-color: #00de7b;

        &::before {
          background-position: -421px -1015px;
        }
      }
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 201px;
    padding: 20px 20px 24px;
    border-radius: 16px;

    &::before {
      width: 72px;
      height: 72px;
    }
  }

  &__text {
    display: inline-block;
    margin-left: 4px;
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    color: #fff;

    &::after {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 6px;
      background-image: url('../img/pc.png');
      background-size: 500px 3250px;
      background-repeat: no-repeat;
      background-position: -462px 0px;
    }
  }
}

@include breakpoint(tablet) {
  .social {
    .cont_wrap {
      padding-top: 50px;
    }

    &__list {
      margin: 26px -10px -10px;
    }

    &__item {
      width: 50%;
      padding: 10px;

      &.youtube {
        .social__link {
          &::before {
            background-position: -199px -698px;
          }
        }
      }

      &.nstagram {
        .social__link {
          &::before {
            background-position: -198px -749px;
          }
        }
      }

      &.twitter-x {
        img {
          width: 52px;
        }
      }

      &.tiktok {
        .social__link {
          &::before {
            background-position: -199px -858px;
          }
        }
      }

      &.facebook {
        .social__link {
          &::before {
            background-position: -198px -919px;
          }
        }
      }

      &.navertv {
        .social__link {
          &::before {
            background-position: -199px -979px;
          }
        }
      }
    }

    &__link {
      height: 186px;
      padding: 16px 16px 18px;
      border-radius: 8px;

      &::before {
        width: 52px;
        height: 52px;
      }
    }

    &__text {
      margin-left: 0;
      font-size: 13px;

      &::after {
        margin: 0 0 -6px 4px;
        background-image: url('../img/mo.png');
        background-size: 250px 3500px;
        background-position: -213px 0px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .social {
    &__link {
      height: 152px;
    }
  }
}

@include breakpoint(mobile-small) {
  .social {
    .cont_wrap {
      padding-top: 34px;
    }

    &__list {
      margin: 20px 0 0;
    }

    &__item {
      width: 100%;
      margin-top: 12px;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }

      &.youtube {
        .social__link {
          &::before {
            background-position: -207px -1145px;
          }
        }
      }

      &.nstagram {
        .social__link {
          &::before {
            background-position: -207px -1177px;
          }
        }
      }

      &.twitter-x {
        img {
          width: 32px;
        }
      }

      &.tiktok {
        .social__link {
          &::before {
            background-position: -207px -1241px;
          }
        }
      }

      &.facebook {
        .social__link {
          &::before {
            background-position: -207px -1273px;
          }
        }
      }

      &.navertv {
        .social__link {
          &::before {
            background-position: -207px -1305px;
          }
        }
      }
    }

    &__link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      height: 48px;
      padding: 0 12px 0 8px;

      &::before {
        width: 32px;
        height: 32px;
      }
    }

    &__text {
      margin-left: 8px;
      font-size: 12px;

      &::after {
        position: absolute;
        right: 12px;
        margin: 0;
      }
    }
  }
}
