.error {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -100%);
  text-align: center;

  &__text {
    font-weight: bold;
    font-size: 24px;
    color: $color-black;
  }

  &__btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 35px;
    margin-top: 12px;
    padding: 0 10px;
    background-color: $color-black;
    border-radius: 4px;
    font-size: 14px;
    color: $color-white;
  }
}
