.stories-detail {
  font-family: 'NanumSquareNeo';

  .cont_wrap {
    max-width: 1120px;
    padding-top: 80px;
    padding-bottom: 61px;
  }

  .headline_box {
    padding: 18px 0 24px 0;
    border-bottom: 1px solid #353b42;
    color: #353b42;

    dl {
      font-size: 0;
    }

    dt,
    dd {
      display: inline-block;
      padding: 0 5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
    }

    dt {
      position: relative;
      padding-left: 0;

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        top: 50%;
        width: 1px;
        height: 13px;
        background-color: #353b42;
        transform: translateY(-50%);
      }
    }

    .headline_title {
      margin-top: 8px;
      font-weight: 800;
      font-size: 26px;
      line-height: 36px;
    }
  }

  .content_box {
    max-width: 800px;
    margin: 0 auto;
    padding: 48px 0 36px;
    color: #595e65;

    .se-viewer {
      .se-component-content {
        padding: 0;
        max-width: none;
      }
    }

    .se-section-image {
      max-width: none;
    }
  }

  .button_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    border-top: 1px solid #dbe1e2;

    .button_page {
      display: flex;
      align-items: center;
      width: 150px;
      height: 65px;
      padding: 0 36px 0 30px;
      font-weight: 700;
      font-size: 20px;
      color: #bac8ca;
      line-height: 25px;
      text-transform: uppercase;
      border-radius: 40px;
      border: 1px solid #bac8ca;

      [lang='en'] & {
        font-size: 20px;
      }

      +.button_page {
        margin-left: 16px;
      }

      &_wrap {
        display: flex;
        align-items: center;
        font-size: 0;
      }

      &.prev {
        &::before {
          width: 8px;
          height: 12px;
          padding-right: 14px;
          @include sprite(pc);
          background-position: -492px -523px;
          content: '';
        }
      }

      &.next {
        padding: 0 30px 0 36px;

        &::after {
          width: 8px;
          height: 12px;
          @include sprite(pc);
          padding-left: 14px;
          background-position: -492px -523px;
          transform: rotate(180deg);
          content: '';
        }
      }
    }

    .button_list {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 145px;
      height: 65px;
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      line-height: 25px;
      text-transform: uppercase;
      border-radius: 66px;
      background-color: #363b41;

      [lang='en'] & {
        width: 168px;
        font-size: 20px;
      }
    }

    .button_list::before {
      width: 145px;
      height: 66px;
      border-radius: 20px;
      background-position: -355px -2318px;
    }
  }
}

@include breakpoint(tablet) {
  .stories-detail {
    .cont_wrap {
      padding-top: 50px;
      padding-bottom: 60px;
    }

    .headline_box {
      padding: 9px 0 16px 0;

      dt,
      dd {
        padding: 0 4px;
        font-size: 13px;
        line-height: 13px;
      }

      dt {
        padding-left: 0;
      }

      .headline_title {
        font-size: 16px;
        line-height: 23px;
      }
    }

    .content_box {
      max-width: auto;
      padding: 24px 0 48px;

      .se-viewer {
        .se-component-content {
          max-width: none;
        }
      }
    }

    .button_box {
      .button_page {
        width: 153px;
        height: 59px;
        padding: 0 32px 0 35px;
        font-size: 18px;
        line-height: 28px;

        &.prev::before {
          @include sprite(mo);
          background-position: -35px -1506px;
        }

        &.next {
          padding: 0 24px 0 43px;

          &::after {
            @include sprite(mo);
            background-position: -35px -1506px;
          }
        }

        +.button_page {
          margin-left: 12px;
        }
      }

      .button_list {
        width: 152px;
        height: 59px;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}

@include breakpoint(mobile) {
  .stories-detail {
    .cont_wrap {
      padding-bottom: 25px;
    }
  }
}

@include breakpoint(mobile-small) {
  .stories-detail {
    .cont_wrap {
      padding-top: 34px;
      padding-bottom: 24px;
    }

    .headline_box {
      padding: 9px 0 12px 0;

      dt,
      dd {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .content_box {
      padding: 20px 0 24px;
    }

    .button_box {
      padding: 12px 0;

      .button_page {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 43px;
        padding: 0;
        font-size: 0;
        text-indent: -999px;
        border-radius: 50%;

        +.button_page {
          margin-left: 8px;
        }

        &.prev::before {
          padding: 0;
        }

        &.next {
          padding: 0;

          &::after {
            padding: 0;
          }
        }
      }

      .button_list {
        width: 88px;
        height: 44px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;

        .button_list::before {
          border-radius: 10px;
          background-position: -162px -2865px;
        }
      }
    }
  }
}
