.stories {
  display: flex;
  flex: 1;
  font-family: 'NanumSquareNeo';

  .cont_wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1120px;
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .tab {
    &__item.on .tab__link {
      color: #00c75e;
      border-bottom-color: #00c75e;
    }

    &__link {
      display: inline-block;
      padding-bottom: 9px;
      font-weight: 800;
      font-size: 30px;
      color: #424a54;
      letter-spacing: -0.166667px;
      line-height: 30px;
      border-bottom: 3px solid transparent;
    }
  }

  &__list {
    margin: 14px -10px -32px;
    font-size: 0;
  }

  &__item {
    display: inline-block;
    width: 33.3333%;
    padding: 32px 10px;
  }

  &__link {
    width: 100%;
    height: 100%;
  }

  &__title {
    margin-top: 12px;
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    color: #353b42;
    @include multi-ellipsis(2);
  }

  &__desc {
    margin-top: 4px;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #595e65;
    @include multi-ellipsis(3);
  }

  &__date {
    display: inline-block;
    margin-top: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #595e65;
    opacity: 0.7;
  }

  &__img {
    overflow: hidden;
    width: 100%;
    padding-top: 55.4%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 110% auto;
  }

  &__btn {
    display: none;
    margin: 120px auto 0;

    &::before {
      width: 260px;
      height: 91px;
      background-size: 500px 3250px;
      background-position: -241px -1936px;
    }

    [lang='en'] &::before {
      background-position: -241px -2943px;
    }
  }

  &__no-result {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    color: #595e65;

    &.#{$showClass} {
      display: flex;
    }

    &::before,
    &::after {
      content: '';
      flex: 1 0 auto;
    }
  }
}

@include breakpoint(tablet) {
  .stories {
    .tab {
      &__link {
        padding-bottom: 6px;
        font-size: 20px;
        line-height: 20px;
        border-bottom-width: 2px;
      }
    }

    .cont_wrap {
      padding-top: 50px;
    }

    &__list {
      margin: 12px -12px -22px;
    }

    &__item {
      width: 50%;
      padding: 24px 12px;
    }

    &__title {
      margin-top: 12px;
      font-size: 16px;
      line-height: 24px;
    }

    &__desc {
      margin-top: 4px;
      font-size: 13px;
      line-height: 23px;
      @include multi-ellipsis(2);
    }

    &__date {
      margin-top: 4px;
      font-size: 12px;
      line-height: 12px;
    }

    &__img {
      padding-top: 55.6%;
      border-radius: 8px;
    }

    &__btn {
      margin-top: 74px;

      &::before {
        width: 153px;
        height: 60px;
        background-size: 250px 3500px;
        background-position: -97px -1996px;
      }

      [lang='en'] &::before {
        background-position: 0px -3191px;
      }
    }

    &__no-result {
      font-size: 13px;
      line-height: 22px;
    }
  }
}

@include breakpoint(mobile) {
  .stories {
    &__item {
      padding: 24px 10px;
    }

    &__title {
      margin-top: 8px;
    }

    &__desc {
      margin-top: 4px;
      font-size: 12px;
      line-height: 18px;
    }

    &__date {
      margin-top: 8px;
    }

    &__img {
      padding-top: 55.3%;
    }
  }
}

@include breakpoint(mobile-small) {
  .tab {
    flex-direction: column;

    &__item {
      margin-left: 12px;
    }

    .tab__link {
      font-size: 15px;
      line-height: 16px;
      padding-bottom: 4px;
    }
  }

  .stories {
    .cont_wrap {
      padding-top: 34px;
      padding-bottom: 60px;
    }

    &__list {
      margin: 24px 0 0 0;
    }

    &__item {
      width: 100%;
      margin-top: 36px;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &__title {
      margin-top: 12px;
    }

    &__desc {
      margin-top: 4px;
      line-height: 18px;
    }

    &__date {
      margin-top: 8px;
    }

    &__btn {
      margin-top: 40px;
    }

    &__no-result {
      font-size: 12px;
      line-height: 18px;
    }

    &__desc {
      font-size: 12px;
    }

    &__img {
      padding-top: 55.2%;
    }
  }
}
