.notice {
  .cont_wrap {
    max-width: 1130px;
  }

  &__title {
    font-weight: 700;
    font-size: 30px;
    color: #353b42;
  }

  &__contents {
    margin-top: 40px;

    table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      text-align: center;
      table-layout: fixed;
    }

    colgroup {
      .date {
        width: 184px;
      }
    }

    th {
      height: 71px;
      font-weight: 400;
      font-size: 16px;
      border: 1px solid #ced4db;
      border-width: 1px 0 1px 0;
      color: #353b42;
    }

    td {
      height: 55px;
      border-bottom: 1px solid #e9e9f1;
      font-size: 15px;
      color: #353b42;
    }
  }

  &__tit {
    a {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #353b42;
      text-align: left;
    }
  }

  &__date {
    width: 184px;
  }

  &__page {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    font-size: 0;
    text-align: center;

    &-inner {
      display: inline-flex;
    }

    a {
      display: inline-block;
      padding: 0 18px;
      font-size: 18px;
      color: #adb4bc;

      &.is-active {
        color: #353b42;
        cursor: default;
      }
    }

    button {
      display: none;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;

      &::before {
        width: 14px;
        height: 14px;
      }

      &.#{$activeClass} {
        display: inline-flex;
      }

      &.btn_prev {
        &::before {
          background-position: 0 -1755px;
          content: '';
        }
      }

      &.btn_next {
        &::before {
          background-position: 0 -1774px;
          content: '';
        }
      }
    }

  }

  //NOTE: 공고 상세
  &-detail {
    &__contents {
      margin-top: 40px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 82px;
      border: 1px solid #e9e9f1;
      border-width: 1px 0 1px 0;
      font-size: 0;

      h3 {
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        color: #353b42;
      }

      span {
        display: inline-block;
        font-size: 14px;
        color: #595e65;
      }
    }

    &__box {
      padding: 70px 0;
      border-bottom: 1px solid #e9e9f1;
    }

    &__btn {
      margin-top: 40px;
      text-align: center;

      a {
        display: inline-flex;
        align-items: center;
        min-width: 90px;
        height: 60px;
        padding: 30px;
        border: solid 1px #353b42;
        border-radius: 33px;
        font-size: 15px;
        color: #353b42;
      }
    }
  }
}

@include breakpoint(tablet) {
  .notice {
    &__page {
      a {
        font-size: 16px;
      }

      button {
        &.btn_prev {
          &::before {
            background-position: 0 -1736px;
          }
        }

        &.btn_next {
          &::before {
            background-position: -19px -1736px;
          }
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .notice {
    &__title {
      font-size: 22px;
    }

    &__contents {
      margin-top: 8px;

      colgroup {
        .date {
          width: 80px;
        }
      }

      thead {
        display: none;
      }

      th, td {
        font-size: 14px;
      }

      tr {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 10px;

        &:first-child {
          margin-top: 0;
        }
      }

      td {
        width: 100%;
        height: auto;
        border: none;
      }
    }

    &__date {
      margin-top: 26px;
      width: 100%;
    }

    &__page {
      a {
        font-size: 16px;
      }
    }

    //NOTE: 공고 상세
    &-detail {
      &__contents {
        margin-top: 12px;
      }

      &__title {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-top: none;
      }

      &__box {
        padding: 30px 0;
      }

      &__btn {
        a {
          min-width: 78px;
          height: 55px;
          padding: 0 24px;
          border-radius: 28px;
        }
      }
    }
  }
}
