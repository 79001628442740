.policy {
  .cont_wrap {
    max-width: 1130px;
  }

  &__list {
    font-size: 0;
  }

  &__item {
    display: inline-block;
    position: relative;
    padding: 0 16px;

    &:first-child {
      padding-left: 0;
    }

    &.is-active {
      .policy__link {
        color: #00c75e;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -10px;
          height: 3px;
          background-color: #00c75e;
        }
      }
    }
  }

  &__link {
    position: relative;
    font-weight: 700;
    font-size: 30px;
    color: #424a54;
    letter-spacing: -0.11px;
  }

  &__contents {
    margin-top: 92px;

    [lang="en"] & {
      margin-top: 0;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    color: #353b42;
  }

  &__btn-prev {
    display: none;
    margin-top: 36px;
    font-weight: 700;
    font-size: 15px;
    color: #353b42;
  }

  &__inner {
    margin-top: 72px;
  }
}

@include breakpoint(tablet) {
  .policy {
    &__item {
      padding: 0 8px;

      &.is-active {
        .policy__link {
          &::after {
            bottom: -5px;
            height: 2px;
          }
        }
      }
    }

    &__link {
      font-size: 20px;
    }

    &__contents {
      margin-top: 44px;
    }

    &__title {
      font-size: 22px;
      line-height: 30px;
    }

    &__btn-prev {
      margin-top: 24px;
      font-size: 14px;
    }

    &__desc {
      margin-top: 48px;
      font-size: 14px;
      line-height: 26px;
    }

    &__box {
      margin-top: 32px;

      li {
        padding: 32px 0 28px;
      }

      h3 {
        font-size: 14px;
      }

      strong {
        font-size: 14px;
      }

      p {
        font-size: 14px;

        &.point-gray {
          margin-top: 16px;
          font-size: 14px;

          &+.point-gray {
            margin-top: 24px;
          }
        }

        &+strong {
          margin-top: 24px;
        }
      }

      table {
        margin-top: 8px;
      }

      th {
        padding: 20px 12px;
        font-size: 12px;
      }

      td {
        padding: 11px 12px;
        font-size: 12px;
      }
    }
  }
}

@include breakpoint(mobile-small) {
  .policy {
    &__item {
      padding: 0 4px;
    }

    &__link {
      font-size: 13px;
    }

    &__contents {
      margin-top: 48px;
    }

    &__title {
      font-size: 22px;
    }

    &__btn-prev {
      font-size: 14px;
    }

    &__desc {
      font-size: 14px;
      line-height: 24px;
    }
  }
}
