body,
input,
select,
textarea,
button {
  font-family: 'NanumSquare', '나눔스퀘어', '나눔고딕', 'Dotum', '돋움', Helvetica, Arial, AppleSDGothicNeo, sans-serif;

}

fieldset,
img {
  border: 0;
}

img {
  width: 100%;
  vertical-align: top;
}

.blind,
caption span,
legend {
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  text-indent: -9999px;
}

.skip {
  height: 0
}

.skip a {
  display: block;
  position: absolute;
  left: 0;
  top: -100px;
  width: 100%;
  height: 25px;
  text-align: center
}

.skip a:focus,
#skip a:active {
  position: absolute;
  top: 0;
  z-index: 100;
  padding: 10px 0;
  background: #000;
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  text-decoration: none
}

.wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding-bottom: 91px;
}

.cont_wrap {
  padding: 97px 40px 80px;
  margin: 0 auto;
  max-width: $screen-lg;
  box-sizing: content-box;
}

.sp_ico::before {
  content: '';
  @include sprite(pc);
}

.br {
  &-pc {
    display: block;
  }

  &-mo {
    display: none;

    &_768 {
      display: none;
    }

    &_640 {
      display: none;
    }
  }
}

@include breakpoint(tablet) {
  .wrap {
    padding-bottom: 61px;
  }

  .cont_wrap {
    padding: 50px 40px 60px;
  }

  .sp_ico::before {
    @include sprite(mo);
  }

  .br {
    &-pc {
      display: none;
    }

    &-mo {
      display: block;

      &_768 {
        display: none;
      }

      &_640 {
        display: none;
      }
    }
  }
}

@include breakpoint(mobile) {
  .br {
    &-pc {
      display: none;
    }

    &-mo {
      &_768 {
        display: block;
      }

      &_640 {
        display: none;
      }
    }
  }
}

@include breakpoint(mobile-small) {
  .wrap {
    padding-bottom: 121px;
  }

  .cont_wrap {
    padding: 34px 20px 60px;
  }

  .br {
    &-pc {
      display: none;
    }

    &-mo {
      display: none;

      &_768 {
        display: none;
      }

      &_640 {
        display: block;
      }
    }
  }
}
