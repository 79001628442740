.impact {
  .cont_wrap {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .br_tab,
  .br_mo,
  .br_sm {
    display: none;
  }

  .section_inner {
    max-width: 1120px;
  }

  .webtoon-with {
    .tab {
      &__item.on .tab__link {
        color: #00c75e;
        border-bottom-color: #00c75e;
      }

      &__link {
        display: inline-block;
        padding-bottom: 9px;
        font-weight: 800;
        font-size: 30px;
        color: #424a54;
        letter-spacing: -0.166667px;
        line-height: 30px;
        border-bottom: 3px solid transparent;
      }
    }

    &_sub {
      margin-top: 48px;

      &-title {
        font-weight: 800;
        font-size: 21px;
        color: #353b42;
        line-height: 28px;
      }

      &-text {
        padding-top: 12px;
        font-weight: 700;
        font-size: 17px;
        color: #595e65;
        line-height: 30px;
      }
    }

    &_image {
      margin-top: 24px;
      overflow: hidden;
      border-radius: 16px;
      background-color: #000;

      .img-box {
        width: 100%;
        height: 425px;
        background-color: #000;
      }
    }

    &_info {
      margin-top: 32px;
      font-weight: 700;

      &-inner {
        display: flex;

        div[class*='info-text'] {
          width: 50%;
        }
      }

      &-text1 {
        font-size: 15px;
        color: #595e65;
        line-height: 30px;
        word-break: keep-all;
      }

      &-text2 {
        padding-left: 9px;

        .txt,
        .txt_item {
          font-size: 15px;
          color: #595e65;
          line-height: 30px;
        }

        .txt {
          font-weight: 800;

          &_list {
            padding-left: 20px;
          }

          &_item {
            position: relative;
            word-break: keep-all;

            &::before {
              display: block;
              position: absolute;
              top: 13px;
              left: -12px;
              width: 3px;
              height: 3px;
              background-color: #595e65;
              content: '';
            }
          }
        }
      }

      &-download {
        position: relative;
        padding-right: 21px;
        font-weight: 700;
        font-size: 15px;
        color: #00dc64;
        line-height: 30px;
        border-bottom: 1px solid #00dc64;

        &::before {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          @include sp-desktop('ico_down');
          content: '';
        }
      }
    }
  }

  .creator {
    margin-top: 84px;

    &_area {
      display: flex;
      flex-direction: column;
      align-items: center;

      div[class*='area-'] {
        width: 100%;
      }

      &-image {
        margin: 24px 0;
      }

      &-list {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      &-top {
        .creator_area-item {
          width: 23.67%;
        }
      }

      &-bottom {
        .creator_area-item {
          width: 24.375%;
        }
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        .item-box {
          width: 188px;
          height: 160px;
        }

        .item-text {
          padding-top: 20px;
          font-weight: 700;
          font-weight: 700;
          font-size: 15px;
          color: #595e65;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .impact {
    .cont_wrap {
      padding-top: 50px;
    }

    .br_pc {
      display: none;
    }

    .br_tab {
      display: block;
    }

    .cont_wrap {
      padding-bottom: 76px;
    }

    .section_inner {
      padding: 0;
    }

    .webtoon-with {
      .tab__link {
        padding-bottom: 6px;
        font-size: 20px;
        line-height: 20px;
        border-bottom-width: 2px;
      }

      &_sub {
        margin-top: 36px;

        &-title {
          font-size: 16px;
          line-height: 24px;
        }

        &-text {
          padding-top: 8px;
          font-size: 13px;
          line-height: 23px;
        }
      }

      &_image {
        margin-top: 16px;
        border-radius: 8px;
      }

      &_info {
        margin-top: 20px;

        &-inner {
          flex-direction: column;

          div[class*='info-text'] {
            width: 100%;
          }
        }

        &-text1,
        .txt,
        .txt_item,
        &-download {
          font-size: 13px;
          line-height: 23px;
        }

        &-text2 {
          padding-top: 14px;
          padding-left: 0;

          .txt_list {
            padding-left: 12px;
          }

          .txt_item::before {
            width: 2px;
            height: 2px;
            top: 10px;
            left: -8px;
          }
        }

        &-download {
          margin-top: 16px;
          padding-right: 18px;
        }
      }
    }

    .creator {
      margin-top: 56px;

      &_area {
        &-top {
          .creator_area-item {
            width: 22.82%;
          }
        }

        &-bottom {
          .creator_area-item {
            width: 216px;
          }
        }

        .item-box {
          width: 142px;
          height: 120px;
        }

        .item-text {
          padding-top: 12px;
          font-size: 13px;
          line-height: 22px;
        }

        &-image {
          margin: 16px 0;
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .impact {

    .br_pc,
    .br_tab {
      display: none;
    }

    .br_mo {
      display: block;
    }

    .cont_wrap {
      padding-bottom: 60px;
    }

    .webtoon-with {
      &_sub {
        &-text {
          font-size: 12px;
          letter-spacing: -0.1px;
          line-height: 22px;
        }
      }

      &_info {

        &-text1,
        .txt,
        .txt_item,
        &-download {
          font-size: 12px;
          line-height: 22px;
          letter-spacing: -0.1px;
        }
      }
    }

    .creator {
      margin-top: 51px;

      &_title {
        @include sp-mobile('creator_title');
      }

      &_area {
        margin-top: 9px;

        &-image {
          display: none;
        }

        &-list {
          display: block;
          margin-left: 0;
        }

        &-bottom {
          .creator_area-item {
            &:last-child {
              width: auto;
            }
          }
        }

        &-top,
        &-bottom {
          .creator_area-item {
            float: left;
            flex-direction: row;
            width: 48.58%;
            margin-top: 16px;
            margin-left: 0;

            &:nth-child(even) {
              float: right;
            }

            .item-box {
              width: 106px;
              height: 90px;
            }
          }
        }

        .item-text {
          padding-top: 0;
          padding-left: 12px;
          font-size: 12px;
          line-height: 18px;
          text-align: left;
        }
      }
    }
  }
}

@include breakpoint(mobile-small) {
  .impact {
    .cont_wrap {
      padding-top: 34px;
    }

    .br_tab,
    .br_mo {
      display: none;
    }

    .br_sm {
      display: block;
    }

    .webtoon-with {
      .tab__link {
        font-size: 15px;
        line-height: 16px;
        padding-bottom: 4px;
      }

      &_sub {
        margin-top: 20px;
      }

      &_info {
        margin-top: 16px;
      }
    }

    .creator {
      &_area {
        margin-top: 23px;
        align-items: flex-start;

        br {
          display: none;
        }

        &-list {
          display: flex;
          flex-direction: column;
          margin-top: -24px;
        }

        .item-box {
          flex-shrink: 0;
        }

        .item-text {
          letter-spacing: -0.1px;
          word-break: keep-all;
        }

        &-bottom {
          margin-top: 24px;
        }

        &-top,
        &-bottom {
          .creator_area-item {
            float: none;
            width: 100%;
            margin-top: 24px;

            &:nth-child(even) {
              float: none;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
