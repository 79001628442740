.visual {
  position: relative;
  height: 822px;
  background-color: #eaf8f1;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../img/pc/visual_deco.png");
    background-position: 50% 0;
    background-size: 2279px 822px;
    background-repeat: no-repeat;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 210px;
    background-image: url("../img/pc/visual_bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__list {
    overflow: hidden;
    @include absolute-full;
  }

  &__item {
    position: absolute;
    left: 50%;
    z-index: $z-index-minimum;
    background-position: 0 0;
    background-repeat: no-repeat;

    &.first {
      top: 145px;
      width: 399px;
      height: 381px;
      margin-left: -200px;
      background-image: url("../img/pc/visual_logo.png");
      background-size: 399px 381px;
    }

    &.second {
      top: 205px;
      width: 243px;
      height: 213px;
      margin-left: -956px;
      background-image: url("../img/pc/visual_ico1.png");
      background-size: 243px 213px;
    }

    &.third {
      top: 226px;
      width: 513px;
      height: 550px;
      margin-left: -644px;
      background-image: url("../img/pc/visual_ico2.png");
      background-size: 513px 550px;
    }

    &.fourth {
      top: 95px;
      width: 237px;
      height: 375px;
      margin-left: 355px;
      background-image: url("../img/pc/visual_ico3.png");
      background-size: 237px 375px;
    }

    &.fifth {
      top: 408px;
      width: 235px;
      height: 188px;
      margin-left: 732px;
      background-image: url("../img/pc/visual_ico4.png");
      background-size: 235px 188px;
    }
  }
}

.section {
  position: relative;
  background-color: white;

  &_inner {
    position: relative;
    max-width: $screen-lg;
    margin: 0 auto;
    padding: 0 40px;
    font-size: 0;
    box-sizing: content-box;
  }

  &_txt {
    font-size: 22px;
    line-height: 36px;
    color: #595e65;
  }

  &_webtoon {
    .section {
      &_inner {
        padding-top: 90px;

        h2::before {
          width: 483px;
          height: 41px;
          background-position: -1px -2635px;
        }
      }

      &_txt {
        margin-top: 18px;

        &:first-of-type {
          margin-top: 44px;
        }
      }
    }

    h3 {
      margin-top: 26px;
      font-weight: 800;
      font-size: 26px;
      color: #353b42;
    }

    .btn_vod_view {
      margin-top: 50px;

      &::before {
        width: 166px;
        height: 58px;
        background-position: -3px -2695px;
      }
    }
  }

  &_news {
    .section_inner {
      padding-top: 124px;
      overflow: hidden;

      h2::before {
        width: 170px;
        height: 54px;
        background-position: -1px -2941px;
      }
    }

    .news_list {
      margin: 20px -12px -12px;
      font-size: 0;

      .item {
        display: inline-block;
        width: calc(100% / 3);
        padding: 12px;
      }

      .link {
        width: 100%;
        height: 100%;
      }

      .img_box {
        overflow: hidden;
        position: relative;
        width: 100%;
        padding-top: 56.1%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 16px;
        border: 1px solid rgba($color-black, 0.07);
      }

      strong {
        margin-top: 12px;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #353b42;
        @include multi-ellipsis(2);
      }
    }
  }

  &_history {
    .section_inner {
      padding-top: 140px;

      h2::before {
        width: 237px;
        height: 41px;
        background-position: -1px -2782px;
      }
    }

    .bg_history {
      position: relative;
      margin-top: 52px;
    }
  }

  &_service {
    .section_inner {
      padding-top: 120px;

      [lang='en'] & {
        padding-bottom: 140px;
      }

      h2::before {
        width: 226px;
        height: 54px;
        background-position: 0 -981px;
      }
    }

    .swiper {
      margin-top: 50px;

      &-button-prev,
      &-button-next {
        display: none;
      }
    }

    .service {
      &_list {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
      }

      &_item {
        display: flex;
        flex-grow: 1;
        gap: 34px;
        width: calc(50% - 34px);
        min-height: 182px;
        margin-top: 28px;
        text-align: center;

        &:first-child,
        &:nth-child(2) {
          margin-top: 0;
        }

        [lang='en'] & {
          min-height: 268px;

          &:nth-child(3),
          &:nth-child(4) {
            min-height: 238px;
          }

          &:nth-child(5),
          &:nth-child(6) {
            min-height: 180px;
          }
        }
      }

      &_img {
        display: block;
        position: relative;

        &::before {
          width: 160px;
          height: 160px;
          background-image: url('../img/pc.png');
          background-repeat: no-repeat;
          background-size: 500px 3250px;
          content: '';
        }

        &.webtoon::before {
          background-position: 0 0;
        }

        &.cartoon::before {
          background-position: -170px 0;
        }

        &.novel::before {
          background-position: 0 -180px;

          [lang='en'] & {
            @include sp-desktop('service_webnovel');
          }
        }

        &.series::before {
          background-position: -170px -180px;
        }

        &.wattpad::before {
          @include sp-desktop('service_wattpad');
        }
      }


      &_con {
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: left;
      }

      &_tit {
        font-weight: 800;
        font-size: 21px;
        color: #353b42;
      }

      &_txt {
        margin-top: 12px;
        min-height: 90px;
        font-size: 17px;
        line-height: 30px;
        color: #595e65;
      }
    }

    .icon {
      display: flex;
      gap: 7px;
      margin-top: 10px;

      .sp_ico {
        &::before {
          width: 45px;
          height: 44px;
        }
      }

      .android::before {
        background-position: 0 -1697px;
      }

      .ios::before {
        background-position: -58px -1697px;
      }

      .web::before {
        background-position: -116px -1697px;
      }
    }
  }

  &_business {
    .section {
      &_inner {
        padding: 120px 40px 110px;

        h2::before {
          width: 266px;
          height: 54px;
          background-position: 0 -1037px;
        }
      }

      &_txt {
        font-weight: 700;
        margin-top: 24px;
        font-size: 17px;
        line-height: 30px;
      }

      &_inner_list {
        display: flex;
        align-items: flex-start;
        gap: 36px;
        margin-top: 46px;

        .item {
          width: 50%;
          min-height: 152px;
          border-top: 1px solid rgba(53, 59, 66, 0.77);
          border-bottom: 1px solid #f2f2f2;

          &.studion {
            padding: 34px 0 30px;

            h3::before {
              width: 182px;
              height: 39px;
              background-position: 0 -2500px;
            }
          }

          &.studiolico {
            padding: 24px 0 30px;

            h3::before {
              width: 95px;
              height: 49px;
              background-position: -141px -2558px;
            }
          }

          &_inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
          }

          &_txt {
            font-size: 17px;
            color: #595e65;
          }
        }

        .btn_more {
          position: relative;
          top: 12%;
          right: 0;

          &::before {
            width: 88px;
            height: 37px;
            background-position: -125px -2007px;
          }

          &_txt {
            display: none;
          }
        }
      }
    }

    .swiper {
      margin-top: 80px;

      &-wrapper {
        display: flex;
        justify-content: center;
        gap: 10px;
      }

      &-slide {
        flex-shrink: 0;
        width: calc(20% - 10px);
      }
    }
  }

  &_careers {
    &::before {
      @include absolute-full;
      background-image: url('../img/pc/careers_bg.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-position-y: top;
      content: '';
    }

    .section_inner {
      padding: 182px 40px 100px;

      h2::before {
        width: 249px;
        height: 54px;
        background-position: 0 -1093px;
      }
    }

    .career {
      &_list {
        display: flex;
        justify-content: space-between;
        gap: 67.5px;
        margin-top: 44px;
      }

      &_item {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 33.3333%;
        z-index: 0;

        &::before {
          position: absolute;
          right: 0;
          bottom: 76px;
          left: 0;
          z-index: $z-index-minus;
          height: 1px;
          background-color: #353b42;
          opacity: 0.77;
          filter: alpha(opacity=77);
          content: '';
        }

        &::after {
          max-width: 375px;
          padding-top: 77.33333333333333%;
          margin-top: 18px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          content: '';
        }

        &:first-child {
          &::after {
            background-image: url("../img/pc/careers1.png");
          }

          .career_txt::before {
            background-position: -174px -1700px;
          }
        }

        &:nth-child(2) {
          &::after {
            background-image: url("../img/pc/careers2.png");
          }

          .career_txt::before {
            background-position: -221px -1700px;
          }
        }

        &:nth-child(3) {
          &::after {
            max-width: 395px;
            padding-top: 73.41772151898734%;
            background-image: url("../img/pc/careers3.png");
          }

          .career_txt::before {
            background-position: -268px -1700px;
          }
        }
      }

      &_txt {
        display: flex;
        font-size: 19px;
        line-height: 26px;
        color: #353b42;

        &::before {
          width: 42px;
          height: 50px;
          margin-right: 10px;
        }
      }
    }

    .careers_txt_mo {
      display: none;
    }

    .btn_recruit {
      position: relative;
      left: 50%;
      margin-top: 5px;
      transform: translateX(-50%);

      &:before {
        width: 260px;
        height: 90px;
        background-position: 0 -1598px;
      }

      [lang='en'] &::before {
        background-position: 0 -1842px;
      }
    }
  }

  &_contact {
    background-color: #00d564;

    .section_inner {
      position: relative;
      padding: 144px 40px 76px;
      overflow: hidden;

      [lang='en'] & {
        padding: 144px 40px 160px;
      }

      h2::before {
        width: 344px;
        height: 54px;
        background-position: 0 -1149px;
      }
    }

    .contact {
      &_bg {
        position: absolute;
        top: 67px;
        right: 50px;
        width: 754px;
        height: 447px;

        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-image: url("../img/pc/map.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          // background-attachment: fixed;
        }
      }

      &_area {
        &_wrap {
          margin: 68px 0 0 4px;
          font-size: 0;

          .btn_inquiry {
            margin-top: 26px;

            &::before {
              width: 188px;
              height: 66px;
              background-position: -312px -1598px;
            }
          }
        }

        &.office {
          .contact_con {
            position: relative;

            dt {
              &::before {
                width: 198px;
                height: 31px;
                background-position: 0 -1204px;
              }
            }
          }

          .btn_map {
            position: relative;
            margin-left: 8px;
            font-size: 15px;
            color: #fff;

            &::before {
              position: absolute;
              bottom: 5px;
              left: 0;
              width: 100%;
              height: 1px;
              content: '';
              background-color: #55e599;
            }

            &::after {
              display: inline-block;
              width: 8px;
              height: 11px;
              margin-left: 6px;
              background-image: url(../img/pc.png);
              background-size: 500px 3250px;
              background-position: -492px 0;
              background-repeat: no-repeat;
              content: '';
            }

            [lang='en'] & {
              position: absolute;
              top: calc(100% + 20px);
              left: 0;
              margin: 0;
            }
          }
        }

        &.partnership {
          margin-top: 55px;

          .contact_con {
            dt {
              &::before {
                width: 191px;
                height: 31px;
                background-position: 0 -1232px;
              }
            }
          }
        }

        dd {
          font-size: 17px;
          line-height: 28px;
          color: #fff;

          &:first-of-type {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .visual {
    height: 400px;
    background-color: $color-white;
    z-index: $z-index-minus;

    &::before {
      @include absolute-full;
      background-image: url("../img/mo/visual_deco_tb.png");
      background-size: 768px 400px;
    }

    &::after {
      height: 100%;
      background-image: url("../img/mo/visual_bg.png");
      background-position-y: bottom;
      z-index: $z-index-minus;
    }

    &__item {
      &.first {
        top: 105px;
        width: 146px;
        height: 139px;
        margin-left: -73px;
        background-image: url("../img/mo/visual_logo.png");
        background-size: 146px 139px;
      }

      &.second {
        top: 36px;
        width: 96px;
        height: 84px;
        margin-left: 169px;
        background-image: url("../img/mo/visual_ico1.png");
        background-size: 96px 84px;
      }

      &.third {
        top: 160px;
        width: 157px;
        height: 168px;
        margin-left: 41px;
        background-image: url("../img/mo/visual_ico2.png");
        background-size: 157px 168px;
      }

      &.fourth {
        top: 53px;
        width: 109px;
        height: 170px;
        margin-left: -193px;
        background-image: url("../img/mo/visual_ico3.png");
        background-size: 109px 170px;
      }

      &.fifth {
        top: 223px;
        width: 111px;
        height: 89px;
        margin-left: -303px;
        background-image: url("../img/mo/visual_ico4.png");
        background-size: 111px 89px;
      }
    }
  }

  .section {
    &_txt {
      font-size: 13px;
      line-height: 23px;
    }

    &_webtoon {
      .section {
        &_inner {
          padding-top: 44px;

          h2 {
            flex-shrink: 0;
            width: 250px;

            &::before {
              width: 250px;
              height: 36px;
              background-position: 0 -2171px;
            }
          }
        }

        &_txt {
          margin-top: 14px;

          &:first-of-type {
            margin-top: 27px;

            [lang='en'] & {
              margin-top: 22px;
            }
          }
        }
      }

      h3 {
        margin-top: 15px;
        font-size: 16px;
        line-height: 23px;
      }

      .btn_vod_view {
        margin-top: 26px;

        &::before {
          width: 122px;
          height: 43px;
          background-position: -128px -1945px;
        }
      }
    }

    &_news {
      .section_inner {
        padding-top: 56px;

        h2 {
          flex-shrink: 0;
          width: 186px;

          &::before {
            width: 90px;
            height: 30px;
            background-position: -159px -2068px;
          }
        }
      }

      .news_list {
        display: flex;
        gap: 8px;
        width: 100%;
        margin: 0;
        margin-top: 22px;

        .item {
          width: 50%;
          padding: 0;
        }

        .img_box {
          border-radius: 8px;
        }

        strong {
          margin-top: 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    &_history {
      .section_inner {
        padding: 61px 39px 0 40px;

        h2 {
          flex-shrink: 0;
          width: 186px;

          &::before {
            width: 129px;
            height: 30px;
            background-position: 0 -2140px;
          }
        }
      }

      .bg_history {
        margin-top: 34px;
      }
    }

    &_service {
      .section_inner {
        padding-top: 70px;

        h2::before {
          width: 121px;
          height: 30px;
          background-position: 0 -768px;
        }
      }

      .swiper {
        position: relative;
        margin-top: 32px;
        padding: 0 40px;

        &-button-prev,
        &-button-next {
          display: block;
          position: absolute;
          top: 56px;
          width: auto;
          height: auto;
          padding: 10px;

          &::before {
            width: 12px;
            height: 18px;
          }

          &::after {
            display: none;
          }
        }

        &-button-prev {
          left: 0;

          &::before {
            background-position: -31px -100px;
          }
        }

        &-button-next {
          right: 0;

          &::before {
            background-position: -14px -100px;
          }
        }
      }

      .service {
        &_list {
          flex-wrap: nowrap;
          gap: 0;
        }

        &_item {
          flex-shrink: 0;
          flex-direction: column;
          align-items: center;
          gap: 18px;
          width: 33.3333%;
          margin-top: 0;

          [lang='en'] & {
            min-height: auto;

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              min-height: auto;
            }
          }
        }

        &_img {
          &::before {
            width: 148px;
            height: 148px;
            background-image: url('../img/mo.png');
            background-size: 250px 3500px;
          }

          &.webtoon::before {
            background-position: 0 -426px;
          }

          &.cartoon::before {
            background-position: 0 -2208px;
          }

          &.novel::before {
            background-position: 0 -2358px;

            [lang='en'] & {
              @include sp-mobile('service_webnovel');
            }
          }

          &.series::before {
            background-position: -102px -1409px;
          }

          &.wattpad::before {
            @include sp-mobile('service_wattpad');
          }
        }

        &_con {
          min-height: 171px;
          justify-content: space-between;
          text-align: center;
        }

        [lang='en'] &_con {
          min-height: 259px;
        }

        &_tit {
          font-size: 16px;
        }

        &_txt {
          margin-top: 8px;
          font-size: 12px;
          line-height: 20px;
          word-break: keep-all;
        }
      }

      .icon {
        position: relative;
        justify-content: center;
        margin-top: 9px;

        .sp_ico {
          &::before {
            width: 48px;
            height: 48px;
          }
        }

        .android::before {
          background-position: 0 -47px;
        }

        .ios::before {
          background-position: -53px -47px;
        }

        .web::before {
          background-position: -106px -47px;
        }
      }
    }

    &_business {
      .section {
        &_inner {
          padding: 60px 40px 85px;

          h2::before {
            width: 142px;
            height: 30px;
            background-position: -1px -803px;
          }
        }

        &_txt {
          margin-top: 18px;
          font-size: 12px;
          line-height: 22px;
        }

        &_inner_list {
          gap: 20px;
          margin-top: 24px;

          .item {
            min-height: 145px;

            &.studion {
              padding: 24px 0;

              h3::before {
                width: 100px;
                height: 22px;
                background-position: 0 -1950px;
              }
            }

            &.studiolico {
              padding: 18px 0 0;

              h3::before {
                width: 53px;
                height: 28px;
                background-position: -13px -1999px;
              }
            }

            &_inner {
              flex-direction: column;
              align-items: flex-start;
              margin-top: 14px;
            }

            &_txt {
              font-size: 12px;
              line-height: 20px;
            }
          }

          .btn_more {
            margin-top: 7px;
            padding-right: 10px;

            &::before {
              position: absolute;
              top: 50%;
              right: 0;
              width: 5px;
              height: 7px;
              background-position: 0 -2005px;
              transform: translateY(-50%);
            }

            &_txt {
              display: inline-block;
              font-size: 12px;
              color: rgba(59, 82, 101, 0.7);
            }
          }
        }
      }

      .swiper {
        margin-top: 48px;

        &-wrapper {
          gap: 5px;
          justify-content: space-between;
        }

        &-slide {
          max-width: 148px;
          // width: calc(20% - 5px);
        }
      }
    }

    &_careers {
      &::before {
        background-image: url('../img/mo/careers_bg.png');
      }

      &::after {
        position: absolute;
        bottom: 0;
        right: 40px;
        width: 280px;
        height: 174px;
        background-image: url('../img/mo/careers_tb.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-position-y: top;
        content: '';
      }

      .section_inner {
        padding: 97px 40px;

        h2::before {
          width: 133px;
          height: 30px;
          background-position: 0 -838px;
        }
      }

      .career_list {
        display: none;
      }

      .careers_txt_mo {
        display: block;
        max-width: 400px;
        margin-top: 20px;
        font-size: 13px;
        line-height: 22px;
        color: #595e65;
      }

      .btn_recruit {
        left: 0;
        margin-top: 17px;
        transform: none;

        &::before {
          width: 153px;
          height: 59px;
          background-position: 0 -1166px;
        }

        [lang='en'] &::before {
          width: 163px;
          background-position: 0 -1294px;
        }
      }
    }

    &_contact {
      .section_inner {
        padding: 36px 40px 82px;

        &::before {
          top: 108px;
          right: auto;
          left: 50%;
          width: 492px;
          height: 291px;
          background-image: url("../img/mo/map_tb.png");
          transform: translateX(-50%);
        }

        h2::before {
          width: 184px;
          height: 50px;
          background-position: 0 -873px;
        }

        [lang='en'] & {
          padding: 36px 40px 105px;
        }
      }

      .contact {
        &_area {
          display: inline-block;
          width: calc(50% - 5px);
          vertical-align: top;

          &_wrap {
            margin: 346px 0 0 0;

            .btn_inquiry {
              position: relative;
              left: 50%;
              margin: 8px 0 0 5px;

              &::before {
                width: 153px;
                height: 59px;
                background-position: 0 -1230px;
              }
            }
          }

          &.office {
            .contact_con {
              position: relative;
              padding-bottom: 30px;

              dt {
                &::before {
                  width: 159px;
                  height: 24px;
                  background-position: 0 -928px;
                }
              }
            }

            .btn_map {
              position: absolute;
              bottom: 0;
              left: 0;
              margin-left: 0;
              font-size: 12px;

              &::before {
                bottom: 2px;
              }

              &::after {
                width: 5px;
                height: 7px;
                background-image: url('../img/mo.png');
                background-size: 250px 3500px;
                background-position: -48px -100px;
              }

              [lang='en'] & {
                top: 120px;
              }
            }
          }

          &.partnership {
            margin: 0 0 0 10px;

            .contact_con {
              dt {
                &::before {
                  width: 153px;
                  height: 24px;
                  background-position: 0 -957px;
                }
              }
            }
          }

          dd {
            font-size: 12px;
            line-height: 22px;

            &:first-of-type {
              margin-top: 0;
            }
          }
        }

        &_bg {
          top: 108px;
          left: 50%;
          right: auto;
          width: 492px;
          height: 291px;
          transform: translateX(-50%);
        }
      }
    }
  }
}

@include breakpoint(mobile) {
  .section {
    &_txt {
      font-size: 12px;
      line-height: 22px;
    }

    &_webtoon {
      .br-mo {
        display: none;
      }

      .section {
        &_inner {
          display: flex;

          h2 {
            width: 186px;

            &::before {
              width: 154px;
              height: 68px;
              background-position: 0 -2072px;
            }
          }
        }

        &_txt {
          &:first-of-type {
            margin-top: 16px;

            [lang='en'] & {
              margin-top: 0;
            }
          }
        }
      }

      h3 {
        margin-top: 5px;
      }

      .btn_vod_view {
        margin-top: 18px;
      }
    }

    &_news {
      .section {
        &_inner {
          display: flex;
        }
      }

      .news_list {
        margin-top: 0;

        .item {
          &:last-child {
            display: none;
          }
        }
      }
    }

    &_history {
      .section {
        &_inner {
          display: flex;
        }
      }

      .bg_history {
        max-width: 374px;
        margin-top: 0;
      }
    }

    &_service {
      .service {
        &_item {
          width: 50%;
        }
      }
    }

    &_business {
      .swiper {
        margin: 48px -40px 0;
        padding: 0 40px;

        &-wrapper {
          gap: initial;
        }

        &-slide {
          width: 148px;
        }
      }
    }
  }
}

@include breakpoint(mobile-small) {
  .visual {
    height: 380px;

    &::before {
      background-image: url("../img/mo/visual_deco.png");
      background-size: 330px 380px;
    }

    &__item {

      &.second,
      &.fifth {
        display: none;
      }

      &.third {
        margin-left: -8px;
      }

      &.fourth {
        top: 64px;
        margin-left: -151px;
      }
    }
  }

  .section {
    &_inner {
      padding: 0 20px;
    }

    &_webtoon {
      .section_inner {
        flex-direction: column;
        padding-top: 24px;
      }

      .section_txt {
        &:first-of-type {
          [lang='en'] & {
            margin-top: 15px;
          }
        }
      }

      h3 {
        margin-top: 8px;
      }
    }

    &_news {
      .section_inner {
        flex-direction: column;
        padding-top: 54px;
      }

      .news_list {
        flex-direction: column;
        gap: 20px;
        margin-top: 27px;

        .item {
          width: 100%;

          &:last-child {
            display: block;
          }
        }

        strong {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }

    &_history {
      .section_inner {
        flex-direction: column;
        padding: 58px 20px 0;

        [lang='en'] & {
          padding: 54px 20px 0;
        }
      }

      .bg_history {
        max-width: 280px;
        margin-top: 25px;
      }
    }

    &_service {
      .service {
        &_item {
          width: 100%;
        }
      }
    }

    &_business {
      .section {
        &_inner {
          padding: 62px 20px 85px;
        }

        &_inner_list {
          gap: 0;
          flex-direction: column;

          .item {
            width: 100%;
            min-height: 120px;

            &.studion {
              padding: 20px 0;
            }

            &.studiolico {
              padding: 20px 0;
              border-top: none;
            }
          }
        }
      }

      .swiper {
        margin: 48px -20px 0;
        padding: 0 20px;
      }
    }

    &_careers {
      .section_inner {
        padding: 122px 20px 180px;
      }

      .btn_recruit {
        margin-top: 24px;
      }
    }

    &_contact {
      .section_inner {
        padding: 36px 20px 54px;

        &::before {
          top: 110px;
          width: 295px;
          height: 175px;
          background-image: url("../img/mo/map.png");
        }

        [lang='en'] & {
          padding: 36px 20px 54px;
        }
      }

      .contact {
        &_area {
          width: 100%;

          &_wrap {
            margin-top: 228px;

            .btn_inquiry {
              left: 0;
              margin: 30px 0 0 0;
            }
          }

          &.office {
            .contact_con {
              padding-bottom: 38px;
            }

            .btn_map::before {
              [lang='en'] & {
                bottom: 14px;
              }
            }
          }

          &.partnership {
            margin: 38px 0 0 0;
          }

          dd {
            &:first-of-type {
              margin-top: 5px;
            }
          }
        }

        &_bg {
          width: 295px;
          height: 175px;

          &::before {
            background-image: url(../../img/mo/map.png);
          }
        }
      }
    }
  }
}
