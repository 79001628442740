@use "sass:math";

$desktop-vars: (
	'careers': (
		offset-x: -384px,
		offset-y: -384px,
		width: 184px,
		height: 52px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'careers_on': (
		offset-x: -196px,
		offset-y: -384px,
		width: 184px,
		height: 56px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'company': (
		offset-x: -212px,
		offset-y: -324px,
		width: 208px,
		height: 52px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'company_on': (
		offset-x: 0px,
		offset-y: -324px,
		width: 208px,
		height: 56px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'contact': (
		offset-x: -424px,
		offset-y: -324px,
		width: 192px,
		height: 52px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'contact_on': (
		offset-x: 0px,
		offset-y: -384px,
		width: 192px,
		height: 56px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'ico_down': (
		offset-x: -572px,
		offset-y: -384px,
		width: 32px,
		height: 32px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'impact': (
		offset-x: -432px,
		offset-y: -444px,
		width: 148px,
		height: 30px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'impact_on': (
		offset-x: 0px,
		offset-y: -444px,
		width: 152px,
		height: 56px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'media': (
		offset-x: -294px,
		offset-y: -444px,
		width: 134px,
		height: 52px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'media_on': (
		offset-x: -156px,
		offset-y: -444px,
		width: 134px,
		height: 56px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'service_wattpad': (
		offset-x: 0px,
		offset-y: 0px,
		width: 320px,
		height: 320px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
	'service_webnovel': (
		offset-x: -324px,
		offset-y: 0px,
		width: 320px,
		height: 320px,
		total-width: 644px,
		total-height: 500px,
		imageSrc: '../img/desktop.png'
	),
);
$desktop-origin: (
  total-width: 644px,
  total-height: 500px,
  imageSrc: '../img/desktop.png'
);

@mixin sprite-desktop-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-desktop-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-desktop-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-desktop {
  @include sprite-desktop-size($desktop-origin);
  @include sprite-desktop-image($desktop-origin);
}

@mixin sp-desktop($image, $size: true) {
  @include set-desktop;
	background-position: math.div(map-get(map-get($desktop-vars, $image), 'offset-x'), 2)
	math.div(map-get(map-get($desktop-vars, $image), 'offset-y'), 2);
	@if $size {
		width: math.div(map-get(map-get($desktop-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($desktop-vars, $image), 'height'), 2);
	}
}
