.tab {
  display: flex;
  justify-content: space-between;

  &__list {
    display: flex; // 767 이전 탭 떨어짐 방지
    font-size: 0;
  }

  &__item {
    display: inline-block;
    margin-left: 32px;

    &:first-child {
      margin-left: 0;
    }

    &.on {
      .tab__link {
        &--media::before {
          background-position: -182px -1424px;
        }

        &--social::before {
          background-position: -182px -1530px;
        }

        [lang='en'] & {
          &--media::before {
            background-position: -286px -1217px;
          }

          &--social::before {
            background-position: -320px -1329px;
          }
        }
      }
    }
  }

  &__link {
    &--media {
      &::before {
        width: 109px;
        height: 42px;
        background-position: -182px -1374px;
      }
    }

    &--social {
      &::before {
        width: 136px;
        height: 42px;
        background-position: -182px -1484px;
      }
    }

    [lang='en'] & {
      &--media::before {
        width: 215px;
        height: 42px;
        background-position: -286px -1276px;
      }

      &--social::before {
        width: 215px;
        height: 42px;
        background-position: -321px -1397px;
      }
    }
  }
}

.media {
  display: flex;
  flex: 1;
  font-family: 'NanumSquareNeo';

  .cont_wrap {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 1120px;
    padding-top: 80px;
    font-size: 0;
  }

  .search {
    display: flex;
    align-items: center;
    position: relative;
    width: 338px;
    height: 48px;
    margin-top: -4px;
    padding-left: 24px;
    border-radius: 24px;
    background: #f7f7fb;

    &__input {
      width: 100%;
      height: 100%;
      border: 0;
      background: none;
      outline: 0;

      &::placeholder {
        font-weight: 400;
        font-size: 15px;
        color: #a6adb4;
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 24px;

      &::before {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-position: 0 -1794px;
      }
    }
  }

  &__list {
    margin: 12px -12px -32px;
    font-size: 0;
  }

  &__item {
    display: inline-block;
    width: 33.3333%;
    padding: 32px 12px;
  }

  &__link {
    width: 100%;
    height: 100%;
  }

  &__title {
    margin-top: 12px;
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    color: #353b42;
    @include multi-ellipsis(2);
  }

  &__desc {
    margin-top: 4px;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #595e65;
    @include multi-ellipsis(3);
  }

  &__date {
    display: inline-block;
    margin-top: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #595e65;
    opacity: 0.7;
  }

  &__img {
    overflow: hidden;
    width: 100%;
    padding-top: 55.9%;
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__btn {
    display: none;
    margin: 120px auto 0;

    &::before {
      width: 260px;
      height: 91px;
      background-size: 500px 3250px;
      background-position: -241px -1936px;
    }

    [lang='en'] &::before {
      background-position: -241px -2943px;
    }
  }

  &__no-result {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    color: #595e65;

    &.#{$showClass} {
      display: flex;
    }

    &::before,
    &::after {
      content: '';
      flex: 1 0 auto;
    }
  }
}

@include breakpoint(tablet) {
  .tab {
    &__item {
      margin-left: 18px;

      &.on {
        .tab__link {
          &--media::before {
            background-position: -125px -1045px;
          }

          &--social::before {
            background-position: -125px -1107px;
          }

          [lang='en'] & {
            &--media::before {
              background-position: -107px -3005px;
            }

            &--social::before {
              background-position: -129px -3081px;
            }
          }
        }
      }
    }

    &__link {
      &--media::before {
        width: 73px;
        height: 28px;
        background-position: -125px -1015px;
      }

      &--social::before {
        width: 91px;
        height: 28px;
        background-position: -125px -1078px;
      }

      [lang='en'] & {
        &--media::before {
          width: 143px;
          height: 28px;
          background-position: -106.5px -3047.5px;
        }

        &--social::before {
          width: 121px;
          height: 28px;
          background-position: -128px -3116px;
        }
      }
    }
  }

  .media {
    .cont_wrap {
      padding-top: 50px;
    }

    .search {
      width: 237px;
      height: 36px;
      margin-top: -5px;
      padding-left: 16px;

      &__input {
        &::placeholder {
          font-size: 12px;
        }
      }

      &__btn {
        padding: 0 14px;

        &::before {
          width: 14px;
          height: 14px;
          background-position: -20px -1506px;
        }
      }
    }

    &__list {
      margin: 11px -12px -22px;
    }

    &__item {
      width: 50%;
      padding: 22px 12px;
    }

    &__title {
      margin-top: 12px;
      font-size: 16px;
      line-height: 24px;
    }

    &__desc {
      margin-top: 4px;
      font-size: 13px;
      line-height: 23px;
      @include multi-ellipsis(2);
    }

    &__date {
      margin-top: 4px;
      font-size: 12px;
      line-height: 12px;
    }

    &__img {
      padding-top: 55.6%;
      border-radius: 8px;
    }

    &__btn {
      margin-top: 74px;

      &::before {
        width: 153px;
        height: 60px;
        background-size: 250px 3500px;
        background-position: -97px -1996px;
      }

      [lang='en'] &::before {
        background-position: 0px -3191px;
      }
    }

    &__no-result {
      font-size: 13px;
      line-height: 22px;
    }
  }
}

@include breakpoint(mobile) {
  .media {
    &__list {
      margin-top: 8px;
    }

    &__item {
      padding: 24px 10px;
    }

    &__title {
      margin-top: 8px;
    }

    &__desc {
      margin-top: 4px;
      font-size: 12px;
      line-height: 18px;
    }

    &__date {
      margin-top: 8px;
    }

    &__img {
      padding-top: 55.3%;
    }
  }
}

@include breakpoint(mobile-small) {
  .tab {
    flex-direction: column;

    &__item {
      margin-left: 12px;

      &.on {
        .tab__link {
          &--media::before {
            background-position: -195px -31px;
          }

          &--social::before {
            background-position: -183px -97px;
          }

          [lang='en'] & {
            &--media::before {
              background-position: -142px -3145px;
            }

            &--social::before {
              background-position: -158px -3201px;
            }
          }
        }
      }
    }

    &__link {
      &--media {
        &::before {
          width: 55px;
          height: 22px;
          background-position: -195px -65px;
        }
      }

      &--social {
        &::before {
          width: 68px;
          height: 22px;
          background-position: -183px -132px;
        }
      }

      [lang='en'] & {
        &--media::before {
          width: 108px;
          height: 22px;
          background-position: -142px -3174px;
        }

        &--social::before {
          width: 91px;
          height: 22px;
          background-position: -158px -3228px;
        }
      }
    }
  }

  .media {
    .cont_wrap {
      padding-top: 34px;

    }
    .search {
      width: 100%;
      margin-top: 20px;
    }

    &__list {
      margin: 18px 0 0 0;
    }

    &__item {
      width: 100%;
      margin-top: 30px;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &__title {
      margin-top: 12px;
    }

    &__desc {
      margin-top: 4px;
      line-height: 18px;
    }

    &__date {
      margin-top: 8px;
    }

    &__btn {
      margin-top: 40px;
    }

    &__no-result {
      font-size: 12px;
      line-height: 18px;
    }

    &__desc {
      font-size: 12px;
    }

    &__img {
      padding-top: 55.2%;
    }
  }
}
